@use "../utils/variable.scss" as variable;

h1.h1 {
  font-size: variable.$text-h1-size;
  font-weight: 500;
}

h2.h2 {
  font-size: variable.$text-h2-size;
  font-weight: 500;
}

h3.h3 {
  font-size: variable.$text-h3-size;
  font-weight: 500;
}

h4.h4 {
  font-size: variable.$text-h4-size;
  font-weight: 500;
}

h5.h5 {
  font-size: variable.$text-h5-size;
  font-weight: 500;
  line-height: 24px;
}

$spacers: (
  0,
  4,
  6,
  8,
  10,
  12,
  16,
  20,
  24,
  28,
  32,
  40,
  48,
  60
) !default;

@each $key in $spacers {
  // generate m-* classes
  .m-#{$key} {
    margin: #{$key}px !important;
  }

  .mt-#{$key} {
    margin-top: #{$key}px !important;
  }
  .mb-#{$key} {
    margin-bottom: #{$key}px !important;
  }
  .ml-#{$key} {
    margin-left: #{$key}px !important;
  }
  .mr-#{$key} {
    margin-right: #{$key}px !important;
  }

  .p-#{$key} {
    padding: #{$key}px !important;
  }

  .pt-#{$key} {
    padding-top: #{$key}px !important;
  }
  .pb-#{$key} {
    padding-bottom: #{$key}px !important;
  }
  .pl-#{$key} {
    padding-left: #{$key}px !important;
  }
  .pr-#{$key} {
    padding-right: #{$key}px !important;
  }

  .gap-#{$key} {
    gap: #{$key}px !important;
  }
}