@use "../../utils/variable.scss" as variable;

.donation-widget {
    height: 100vh;
    .last-donation {
        height: 100%;
        align-items: flex-start;
        .widget {
            color: variable.$color-gray;
            h3 {
                font-size: 20px;
                font-weight: 400;
            }

            p {
                font-size: 20px;
                font-weight: 700;
            }
        }
    }
}